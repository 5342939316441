import { z } from "zod"
import { productGetWithImagesSchema } from "./product"
import { userGetSchema } from "./user"
import { idSchema } from "./common"
import { discountCodeGetSchema } from "./discountCode"


export const shoppingCartStatus = z.enum(['active', 'done'])
export type IShoppingCartStatus = z.infer<typeof shoppingCartStatus>


export const shoppingCartItemSchema = z.object({
  product: idSchema,
  quantity: z.number(),
})
export type IShoppingCartItem = z.infer<typeof shoppingCartItemSchema>


export const shoppingCartItemGetSchema =
  shoppingCartItemSchema.extend({
    product: productGetWithImagesSchema
  })
export type IShoppingCartItemGet = z.infer<typeof shoppingCartItemGetSchema>


export const shoppingCartItemPatchSchema = shoppingCartItemSchema
export type IShoppingCartItemPatch = z.infer<typeof shoppingCartItemPatchSchema>


export const shoppingCartSchema = z.object({
  _id: idSchema,
  items: z.array(shoppingCartItemSchema),
  userId: idSchema,
  discountCodeId: idSchema.optional(),
  status: shoppingCartStatus
})
export type IShoppingCart = z.infer<typeof shoppingCartSchema>


export const shoppingCartGetSchema = shoppingCartSchema.extend({
  items: z.array(shoppingCartItemGetSchema),
  user: userGetSchema,
  discountCode: discountCodeGetSchema
})
export type IShoppingCartGet = z.infer<typeof shoppingCartGetSchema>


export const shoppingCartPostSchema =
  shoppingCartSchema.omit({
    _id: true,
    userId: true
  })
export type IShoppingCartPost = z.infer<typeof shoppingCartPostSchema>


export const shoppingCartPatchSchema = shoppingCartPostSchema
export type IShoppingCartPatch = z.infer<typeof shoppingCartPatchSchema>
