import { addressGetSchema } from './address'
import { z } from 'zod'
import { IQuery, idSchema, stringBooleanTransform, timestampSchema } from './common'
import { userGetSchema } from './user'


export const serviceRequestTypeSchema = z.enum([
  'install',
  'repair',
  'check-replacement-parts'
])
export type IServiceRequestType = z.infer<typeof serviceRequestTypeSchema>


export const serviceRequestStatus = z.enum([
  'requested',
  'technisian-assigned',
  'service-success',
  'service-fail',
])
export type IServiceRequestStatus = z.infer<typeof serviceRequestStatus>


export const serviceRequestSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  type: serviceRequestTypeSchema,
  addressId: idSchema,
  message: z.string(),
  date: z.string(),
  canceled: z.boolean(),
  status: serviceRequestStatus,
})
export type IServiceRequest = z.infer<typeof serviceRequestSchema>


export const serviceRequestGetSchema =
  serviceRequestSchema
    .merge(timestampSchema)
    .extend({
      user: userGetSchema,
      address: addressGetSchema,
    })
export type IServiceRequestGet = z.infer<typeof serviceRequestGetSchema>


export const serviceRequestPostSchema =
  serviceRequestSchema
    .omit({
      _id: true,
      userId: true,
      canceled: true,
      status: true,
    })
export type IServiceRequestPost = z.infer<typeof serviceRequestPostSchema>


export const serviceRequestPatchSchema = serviceRequestPostSchema
export type IServiceRequestPatch = z.infer<typeof serviceRequestPatchSchema>


export const serviceRequestDateTimeSchema = z.object({
  year: z.literal(1402).or(z.literal(1403)),
  month: z.number().min(0).max(11),
  date: z.number().min(0).max(31),
  hour: z.number().min(9).max(21),
  min: z.number().min(0).max(59),
})
export type IServiceRequestDateTime = z.infer<typeof serviceRequestDateTimeSchema>


export const serviceRequestQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
  type: serviceRequestTypeSchema.optional().catch(undefined),
  canceled: z.boolean().optional().catch(undefined),
}).partial()
export type IServiceRequestQuery = z.infer<typeof serviceRequestQuerySchema>

export const serviceRequestQueryParams: IQuery = {
  keyword: { field: ['message'], operator: 'regex', control: 'text' },
  type: { field: 'type', operator: 'equal', control: 'select' },
  canceled: { field: 'canceled', operator: 'equal', control: 'boolean' },
}
