import { z } from 'zod'
import { articleGetSchema, articleListItemSchema } from './article'
import { categoryWithProdductCountSchema } from './category'
import { favoriteGetSchema } from './favorite'
import { orderGetSchema } from './order'
import { productGetWithImagesSchema } from './product'
import { recentlyVisitedGetSchema } from './recentlyVisited'


export const homePageSchema = z.object({
  latestArticles: z.array(articleGetSchema),
  productCountPerCategory: categoryWithProdductCountSchema,
  showcaseProducts: z.array(productGetWithImagesSchema)
})
export type IHomePage = z.infer<typeof homePageSchema>


export const userPagePanelSchema = z.object({
  latestOrder: orderGetSchema.optional(),
  favorites: z.array(favoriteGetSchema),
  recentlyVisited: z.array(recentlyVisitedGetSchema)
})
export type IUserPanelPage = z.infer<typeof userPagePanelSchema>
