import { z } from 'zod'
import { IQuery, idSchema } from './common'


export const tagSchema = z.object({
  _id: idSchema,
  name: z.string(),
})
export type ITag = z.infer<typeof tagSchema>


export const tagGetSchema = tagSchema
export type ITagGet = z.infer<typeof tagGetSchema>


export const tagPostSchema =
  tagSchema.omit({
    _id: true
  })
export type ITagPost = z.infer<typeof tagPostSchema>


export const tagPatchSchema = tagSchema
export type ITagPatch = z.infer<typeof tagPatchSchema>


export const tagListSchema = z.array(tagSchema)
export type ITagList = z.infer<typeof tagListSchema>



export const tagQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
}).partial()
export type ITagQuery = z.infer<typeof tagQuerySchema>

export const tagQueryParams: IQuery = {
  keyword: { field: 'name', operator: 'regex', control: 'text' },
}
