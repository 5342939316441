export const isNumeric = (value: string) => {
  const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  for (let i = 0; i < value.length; i++) {
    if (chars.includes(value[i]) == false)
      return false
  }

  return true
}

export const persianToEnglishDigits = (value: string) =>
  value.toString().replace(/[۰-۹]/g, digit => '۰۱۲۳۴۵۶۷۸۹'.indexOf(digit).toString())

export const arabicToEnglishDigits = (value: string) =>
  value.toString().replace(/[٠-٩]/g, digit => '٠١٢٣٤٥٦٧٨٩'.indexOf(digit).toString())
