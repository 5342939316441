import { z } from 'zod'
import { IQuery, idSchema, timestampSchema } from './common'
import { imageGetSchema } from './image'
import { tagListSchema } from './tag'
import { userGetSchema } from './user'


export const articleTypeSchema = z.enum(['article', 'news'])
export type IArticleType = z.infer<typeof articleTypeSchema>


export const articleSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  title: z.string().min(1).max(100),
  content: z.string().min(1),
  excerpt: z.string().max(1024),
  tags: tagListSchema.optional(),
  active: z.boolean().optional(),
  type: articleTypeSchema,
})
export type IArticle = z.infer<typeof articleSchema>


export const articleGetSchema =
  articleSchema
    .merge(timestampSchema)
    .extend({
      user: userGetSchema,
      images: z.array(imageGetSchema)
    })
export type IArticleGet = z.infer<typeof articleGetSchema>


export const articleListItemSchema =
  articleSchema
    .omit({ content: true })
export type IArticleListItem = z.infer<typeof articleListItemSchema>


export const articlePostSchema =
  articleSchema.omit({
    _id: true,
    userId: true,
  })
export type IArticlePost = z.infer<typeof articlePostSchema>


export const articlePatchSchema = articlePostSchema
export type IArticlePatch = z.infer<typeof articlePatchSchema>


export const articleQuerySchema = z.object({
  keyword: z.string(),
  active: z.boolean(),
}).partial()
export type IArticleQuery = z.infer<typeof articleQuerySchema>


export const articleQueryParams: IQuery = {
  keyword: { field: ['title', 'content', 'excerpt'], operator: 'regex', control: 'text' },
  active: { field: 'active', operator: 'equal', control: 'boolean' }
}
