import { z } from "zod"
import { idSchema, timestampSchema } from './common'
import { isNumeric } from '../utils/string'
import { authUserLogin } from "./auth"


export const loginCodeSchema = z.object({
  _id: idSchema,
  userId: z.string().optional(),
  phone: z.string(),
  code: z.number(),
  expireTime: z.number(),
  expired: z.boolean(),
  used: z.boolean()
}).merge(timestampSchema)
export type ILoginCode = z.infer<typeof loginCodeSchema>


export const loginByPhoneSchema = z.object({
  phone: z.string().length(11).refine(
    value => isNumeric(value),
    { message: 'Use only numbers for phone' }
  )
})
export type ILoginByPhone = z.infer<typeof loginByPhoneSchema>


export const loginByPhoneResponseSchema = z.object({
  isRegistered: z.boolean(),
  codeSent: z.boolean(),
  codeExpireTime: z.number(),
  codeExpireInterval: z.number(),
  isResend: z.boolean(),
  isResendAllowd: z.boolean(),
})
export type ILoginByPhoneResponse = z.infer<typeof loginByPhoneResponseSchema>


export const loginByPhoneCodeSchema = z.object({
  code: z.coerce.number()
})
export type ILoginByPhoneCode = z.infer<typeof loginByPhoneCodeSchema>


export const loginByPhoneCodeResponseSchema = z.object({
  valid: z.boolean(),
  nameEntered: z.boolean().optional(),
  user: authUserLogin.optional(),
  token: z.string().optional()
})
export type ILoginByPhoneCodeResponse = z.infer<typeof loginByPhoneCodeResponseSchema>


export const loginByPhoneNameSchema = z.object({
  firstname: z.string().max(100),
  lastname: z.string().max(100),
  email: z
    .union([z.string().length(0), z.string().email()])
    .optional()
    .transform(value => value === '' ? undefined : value)
})
export type ILoginByPhoneName = z.infer<typeof loginByPhoneNameSchema>


export const loginByPasswordSchema = z.object({
  phone: z.string().length(11),
  password: z.string()
})
export type ILoginByPasswordSchema = z.infer<typeof loginByPasswordSchema>


export const loginByPasswordResponseSchema = z.object({
  isRegistered: z.boolean(),
})
export type ILoginByPasswordResponse = z.infer<typeof loginByPasswordResponseSchema>
