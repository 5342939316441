import { defineStore } from "pinia"
import { useShoppingCartStore } from "../../admin/stores/shoppingCartStore"

export const useBootstrapStore = defineStore('bootstrapStore', () => {
  const init = async (auth = true, shoppingCart = true) => {
    if (auth == true) {
      const authStore = useAuthStore()
      await authStore.tryLoginByLocalToken()
    }

    if (shoppingCart) {
      const shoppingCartStore = useShoppingCartStore()
      await shoppingCartStore.refreshShoppingCart()
    }
  }

  return { init }
})
