import { z } from 'zod'
import { IQuery, idSchema } from './common'
import { userGetSchema } from './user'


export const pointTypeSchema = z.enum(['order', 'like', 'serviceRequest'])
export type IPointType = z.infer<typeof pointTypeSchema>


export const pointSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  type: pointTypeSchema,
  points: z.number()
})
export type IPoint = z.infer<typeof pointSchema>


export const pointGetSchema = pointSchema
  .extend({ user: userGetSchema })
export type IPointGet = z.infer<typeof pointGetSchema>


export const pointPostSchema = pointSchema
  .omit({ _id: true })
export type IPointPost = z.infer<typeof pointPostSchema>


export const pointPatchSchema = pointPostSchema
export type IPointPatch = z.infer<typeof pointPatchSchema>


export const pointQuerySchema = z.object({
  type: pointTypeSchema.optional().catch(undefined),
}).partial()
export type IPointQuery = z.infer<typeof pointQuerySchema>

export const pointQueryParams: IQuery = {
  type: { field: 'type', operator: 'equal', control: 'select' },
}
