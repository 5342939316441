import { z } from 'zod'
import { IQuery, idSchema, timestampSchema } from './common'
import { userGetSchema } from './user'
import { orderGetSchema } from './order'


export const gatewaySchema = z.enum([
  'mellat',
  'melli',
  'parsian'
])
export type IGateway = z.infer<typeof gatewaySchema>


export const transactionDataSchema = z.object({
  Token: z.string(),
  status: z.string(),
  OrderId: z.string(),
  TerminalNo: z.string(),
  RRN: z.string(),
  TspToken: z.string(),
  HashCardNumber: z.string(),
  Amount: z.string(),
  SwAmount: z.string(),
  STraceNo: z.string(),
  RedirectURL: z.string(),
  CallbackError: z.string(),
  VerifyError: z.string(),
  ReverseError: z.string(),
})
export type TransactionData = z.infer<typeof transactionDataSchema>


export const transactionSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  orderId: idSchema,
  amount: z.number(),
  bank: gatewaySchema,
  message: z.string(),
  data: transactionDataSchema,
})
export type ITransaction = z.infer<typeof transactionSchema>


export const transactionGetSchema = transactionSchema
  .merge(timestampSchema)
  .extend({
    user: userGetSchema,
    order: orderGetSchema,
  })
export type ITransactionGet = z.infer<typeof transactionGetSchema>


export const transactionPostSchema =
  transactionSchema.omit({
    _id: true,
    userId: true,
  })
export type ITransactionPost = z.infer<typeof transactionPostSchema>


export const transactionPatchSchema = transactionPostSchema
export type ITransactionPatch = z.infer<typeof transactionPatchSchema>


export const transactionQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
}).partial()
export type ITransactionQuery = z.infer<typeof transactionQuerySchema>

export const transactionQueryParams: IQuery = {
  keyword: { field: 'message', operator: 'regex', control: 'text' },
}
