import { simplifyZodErrors, type ISimplifiedErrorList } from "shared"
import type { z } from "zod"
import { useAuthStore } from "~/stores/authStore"


export const useApi = <TData, TError = void>(
  request: string,
  options?: Parameters<typeof useFetch<TData>>[1]
) => {
  const authStore = useAuthStore()

  return useFetch<TData, TError>(apiUrl(request), {
    ...options,
    headers: {
      Authorization: `Bearer ${authStore.token}`
    },
    cache: 'no-store',
    watch: false,
  })
}


export const useApiWatch = <TData, TError = void>(
  request: string,
  options?: Parameters<typeof useFetch<TData>>[1]
) => {
  const authStore = useAuthStore()

  return useFetch<TData, TError>(apiUrl(request), {
    ...options,
    headers: {
      Authorization: `Bearer ${authStore.token}`
    },
    cache: 'no-store',
  })
}


export const useApiSubmit = async <TData>(
  schema: z.Schema,
  request: string,
  options?: Parameters<typeof useFetch<TData>>[1]
): Promise<Partial<{
  error: ISimplifiedErrorList,
  data: TData
}>> => {
  const result = schema.safeParse(options?.body)

  if (result.success) {
    const { data, error } = await useApi<TData>(request, options)

    if (error.value)
      return { error: simplifyZodErrors(error.value) }
    else {
      return { data: data.value as TData }
    }
  } else
    return { error: simplifyZodErrors(result.error) }
}


export const useApiSubmitPost = async <TData>(
  schema: z.Schema,
  request: string,
  options?: Parameters<typeof useApiSubmit>[2]
) => {
  return await useApiSubmit(schema, request, {
    ...options,
    method: 'post'
  })
}


export const useApiSubmitPut = async <TData>(
  schema: z.Schema,
  request: string,
  options?: Parameters<typeof useApiSubmit>[2]
) => {
  return await useApiSubmit(schema, request, {
    ...options,
    method: 'put'
  })
}
