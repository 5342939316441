import { z } from 'zod'
import { idSchema } from './common'
import { imageGetSchema } from './image'


export const searchSectionSchema = z.enum([
  'product',
  'article',
  'guide'
])
export type ISearchSection = z.infer<typeof searchSectionSchema>


export const searchSchema = z.object({
  search: z.string().min(2).max(100),
  section: z.array(searchSectionSchema).optional()
})
export type ISearch = z.infer<typeof searchSchema>


export const searchResultItem = z.object({
  _id: idSchema,
  section: searchSectionSchema,
  title: z.string(),
  text: z.string(),
  image: imageGetSchema.optional(),
})
export type ISearchResultItem = z.infer<typeof searchResultItem>


export const searchResultListSchema = z.object({
  result: z.array(searchResultItem)
})
export type ISearchResult = z.infer<typeof searchResultListSchema>
