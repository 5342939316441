import { z } from "zod"
import { fileSchema } from "./file"
import { idSchema } from './common'


export const imageTargetTypesSchema = z.enum([
  'user',
  'product',
  'category',
  'article',
])
export type IImageTargetType = z.infer<typeof imageTargetTypesSchema>


export const imageSchema = z.object({
  _id: idSchema,
  title: z.string().optional(),
  alt: z.string().optional(),
  targetId: idSchema,
  targetType: imageTargetTypesSchema,
  file: fileSchema,
  originalFilename: z.string(),
})
export type IImage = z.infer<typeof imageSchema>


export const imageGetSchema = imageSchema
  .omit({ originalFilename: true })
export type IImageGet = z.infer<typeof imageGetSchema>


export const imagePostSchema = imageSchema
  .omit({ _id: true, file: true, originalFilename: true })
  .extend({ file: z.any() })
export type IImagePost = z.infer<typeof imagePostSchema>


export const imageDataPatchSchema = imagePostSchema
  .pick({ title: true, alt: true })
export type IImageDataPatch = z.infer<typeof imageDataPatchSchema>


export const imageFilePatchSchema = imagePostSchema
  .pick({ file: true })
export type IImageFilePatch = z.infer<typeof imageFilePatchSchema>


export const imageSizeSchema = z.enum(['sm', 'md', 'lg'])
export type IImageSize = z.infer<typeof imageSizeSchema>


export const imageListSchema = z.array(imageGetSchema)
export type IImageList = z.infer<typeof imageListSchema>


export const imagePickerSchema = z.object({
  title: z.string().optional(),
  alt: z.string().optional(),
  file: z.any()
})
export type IImagePicker = z.infer<typeof imagePickerSchema>
