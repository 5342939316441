// import { createTRPCNuxtClient, httpBatchLink } from 'trpc-nuxt/client'
// import type { AppRouter } from '../../backend/src/trpc/trpcRouter'

export default defineNuxtPlugin(() => {
  //   const client = createTRPCNuxtClient<AppRouter>({
  //     links: [
  //       httpBatchLink({
  //         url: 'http://localhost:7000/trpc',
  //       }),
  //     ],
  //   })

  //   return {
  //     provide: {
  //       client,
  //     },
  //   }
})
