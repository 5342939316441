import { defineStore } from 'pinia'
import {
  type IID,
  type IProduct,
  type IProductGet,
  type IProductGetWithImages,
  type IShoppingCart,
  type IShoppingCartGet,
  type IShoppingCartItemGet
} from 'shared'


export const useShoppingCartStore = defineStore('shoppingCartStore', () => {
  const shoppingCart = ref<IShoppingCartGet>()
  const list = ref<IShoppingCartItemGet[]>([])

  const authStore = useAuthStore()
  const refreshShoppingCart = async () => {
    if (authStore.isLoggedIn == false)
      return

    const { data, error } = await useApi<IShoppingCartGet>('shopping-cart/user-active')

    if (error.value == undefined) {
      shoppingCart.value = data.value
      if (shoppingCart.value && shoppingCart.value.items)
        list.value = shoppingCart.value.items
    } else
      console.warn(error.value)
  }

  const distinctProductCount = computed(
    () => list.value.length)

  const prodcutCount = computed(() => list.value
    .map(item => item.quantity)
    .reduce((total, current) => total + current, 0))

  const isEmpty = computed(() => list.value.length <= 0)

  const totalPrice = computed(() => {
    let sum = 0
    list.value
      .map(item => item.product.price * item.quantity)
      .forEach(item => sum += item)

    return sum
  })

  const totalDiscount = computed(() => {
    let sum = 0
    list.value
      .map(item => (item.product.price - getProductDiscountedPrice(item.product)) * item.quantity)
      .filter(item => item != undefined)
      .forEach(item => sum += item)

    return sum
  })

  const discountAppliedTotalPrice = computed(
    () => totalPrice.value - totalDiscount.value)

  const discountPercent = computed(
    () => (totalDiscount.value / totalPrice.value) * 100)

  const taxAmount = computed(
    () => discountAppliedTotalPrice.value * getConfig().valueAddedTaxRate)

  const discountCodeAmount = computed(() => {
    if (shoppingCart.value?.discountCode != undefined)
      return -1 * shoppingCart.value?.discountCode.amount
    else
      return 0
  })

  const finalAmount = computed(
    () => discountAppliedTotalPrice.value + taxAmount.value + discountCodeAmount.value)


  const checkProductExistInCart = (
    productId: IID
  ) => {
    const result = list.value.find(item => item.product._id == productId)
    return result != undefined
  }

  const add = async (
    product: IProductGetWithImages,
    quantity = 1
  ) => {
    if (authStore.isLoggedIn == false) {
      navigateTo('/login')
      return
    }

    await refreshShoppingCart()

    if (!checkProductExistInCart(product._id)) {
      const url = `shopping-cart/${shoppingCart.value?._id}/add`

      const { data, error } = await useApi<IShoppingCart>(url, {
        method: 'patch',
        body: {
          product: product._id,
          quantity: 1,
        }
      })

      if (error.value == undefined) {
        list.value = [...list.value, {
          product: product,
          quantity: quantity
        }]
      } else
        console.log(error.value)
    }
  }


  const removeByProduct = async (
    product: IProduct | IProductGet
  ) => {
    const url = `shopping-cart/${shoppingCart.value?._id}/remove/${product._id}`
    const { data, error } = await useApi<IShoppingCart>(
      url,
      { method: 'patch' }
    )

    if (error.value == undefined)
      list.value = list.value
        .filter(item => item.product._id != product._id)
    else
      console.log(error.value)
  }


  const removeByShoppingCartItem = async (
    shoppingCartItem: IShoppingCartItemGet
  ) => {
    const url = `shopping-cart/${shoppingCart.value?._id}/remove/${shoppingCartItem.product._id}`
    const { data, error } = await useApi<IShoppingCart>(
      url,
      { method: 'patch' }
    )

    if (error.value == undefined)
      list.value = list.value
        .filter(item => item.product._id != shoppingCartItem.product._id)
    else
      console.log(error.value)
  }


  const increase = async (
    cartItem: IShoppingCartItemGet
  ) => {
    const url = `shopping-cart/${shoppingCart.value?._id}/increase/${cartItem.product._id}`
    const { data, error } = await useApi<IShoppingCart>(
      url,
      { method: 'patch' }
    )

    if (error.value == undefined)
      return true
    else {
      console.log(error.value)
      return false
    }
  }


  const decrease = async (
    cartItem: IShoppingCartItemGet
  ) => {
    const url = `shopping-cart/${shoppingCart.value?._id}/decrease/${cartItem.product._id}`
    const { data, error } = await useApi<IShoppingCart>(
      url,
      { method: 'patch' }
    )

    if (error.value == undefined)
      return true
    else {
      console.log(error.value)
      return false
    }
  }


  const setQuantity = async (
    cartItem: IShoppingCartItemGet,
    quantity: number
  ) => {
    const item = list.value.find(item => item.product._id = cartItem.product._id)
    if (!item)
      return

    item.quantity = quantity

    const url = `shopping-cart/${shoppingCart.value?._id}/quantity/${cartItem.product._id}/${quantity}`
    const { data, error } = await useApi<IShoppingCart>(
      url,
      { method: 'patch' }
    )

    if (error.value == undefined)
      return true
    else {
      console.log(error.value)
      return false
    }
  }


  const applyDiscountCode = async (
    code: number
  ) => {
    if (!shoppingCart.value)
      return

    const url = `shopping-cart/applyDiscountCode`
    const body = {
      code,
      shoppingCartId: shoppingCart.value._id
    }

    const { data, error } = await useApi<IShoppingCartGet>(url, {
      method: 'post',
      body,
    })

    if (error.value == undefined) {
      shoppingCart.value = data.value
      return true
    } else {
      console.log(error.value)
      return false
    }
  }


  return {
    list,
    isEmpty,
    add,
    removeByProduct,
    removeByShoppingCartItem,
    increase,
    decrease,
    setQuantity,
    checkProductExistInCart,
    discountCodeAmount,
    totalPrice,
    totalDiscount,
    discountAppliedTotalPrice,
    discountPercent,
    refreshShoppingCart,
    taxAmount,
    finalAmount,
    prodcutCount,
    distinctProductCount,
    applyDiscountCode,
  }
})
