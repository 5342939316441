import { z } from "zod";
import { IQuery, idSchema, stringBooleanTransform } from "./common";
import { productGetSchema } from "./product";
import { userGetSchema } from "./user";

export const guaranteeSchema = z.object({
  _id: idSchema,
  // productId: idSchema,
  productName: z.string().optional(),
  serialNumber: z.string(),
  active: z.boolean(),
  activationDate: z.coerce.date().optional(),
  duration: z.number(),
  expireDate: z.coerce.date().optional(),
  userId: idSchema.optional(),
})
export type IGuarantee = z.infer<typeof guaranteeSchema>


export const guaranteeGetSchema = guaranteeSchema
  .extend({
    // product: productGetSchema,
    user: userGetSchema,
  })
export type IGuaranteeGet = z.infer<typeof guaranteeGetSchema>


export const guaranteePostSchema =
  guaranteeSchema.omit({ _id: true })
export type IGuaranteePost = z.infer<typeof guaranteePostSchema>


export const guaranteePatchSchema = guaranteePostSchema
export type IGuaranteePatch = z.infer<typeof guaranteePatchSchema>



export const guaranteeQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
  active: stringBooleanTransform.pipe(z.boolean().optional().catch(undefined))
}).partial()
export type IGuaranteeQuery = z.infer<typeof guaranteeQuerySchema>

export const guaranteeQueryParams: IQuery = {
  keyword: { field: ['serialNumber', 'productName'], operator: 'regex', control: 'text' },
  active: { field: 'active', operator: 'equal', control: 'boolean' }
}
