import { z } from 'zod'
import { IQuery, idSchema } from './common'
import { productGetSchema } from './product'

export const discountSchema = z.object({
  _id: idSchema,
  name: z.string(),
  percent: z.number(),
  productIds: z.array(idSchema),
  from: z.date(),
  to: z.date(),
  active: z.boolean(),
  desc: z.string()
})
export type IDiscount = z.infer<typeof discountSchema>


export const discountGetSchema =
  discountSchema.extend({
    products: z.array(productGetSchema)
  })
export type IDsicountGet = z.infer<typeof discountGetSchema>


export const discountPostSchema =
  discountSchema.omit({
    _id: true
  })
export type IDiscountPost = z.infer<typeof discountPostSchema>


export const discountPatchSchema = discountPostSchema
export type IDiscountPatch = z.infer<typeof discountPatchSchema>



export const discountQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
}).partial()
export type IDiscountQuery = z.infer<typeof discountQuerySchema>

export const discountQueryParams: IQuery = {
  keyword: { field: ['name'], operator: 'regex', control: 'text' },
}