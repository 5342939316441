import { ZodSchema, z } from 'zod'
import { articleGetSchema } from './article'
import { commentGetSchema } from './comment'
import { orderGetSchema } from './order'
import { productGetWithImagesSchema } from './product'
import { userGetSchema } from './user'
import { contactGetSchema } from './contact'
import { categoryGetSchema } from './category'
import { transactionGetSchema } from './transaction'
import { serviceRequestGetSchema } from './serviceRequest'
import { guaranteeGetSchema } from './guarantee'
import { qaGetSchema } from './qa'


export const defaultEmptyPage = {
  data: [],
  page: 1,
  limit: 20,
  total: 0,
  count: 0
}


export const pageSchema = z.object({
  data: z.array(z.any()),
  page: z.number(),
  limit: z.number(),
  total: z.number(),
  count: z.number()
})

export type IPage = z.infer<typeof pageSchema>


export const generateItemPageSchema = <T>(
  itemSchema: ZodSchema<T>
) =>
  pageSchema.extend({ data: z.array(itemSchema) })


export const userPageSchema = generateItemPageSchema(userGetSchema)
export type IUserPage = z.infer<typeof userPageSchema>


export const productPageSchema = generateItemPageSchema(productGetWithImagesSchema)
export type IProductPage = z.infer<typeof productPageSchema>


export const articlePageSchema = generateItemPageSchema(articleGetSchema)
export type IArticlePage = z.infer<typeof articlePageSchema>


export const orderPageSchema = generateItemPageSchema(orderGetSchema)
export type IOrderPage = z.infer<typeof orderPageSchema>


export const commentPageSchema = generateItemPageSchema(commentGetSchema)
export type ICommentPage = z.infer<typeof commentPageSchema>


export const qaPageSchema = generateItemPageSchema(qaGetSchema)
export type IQAPage = z.infer<typeof qaPageSchema>


export const contactPageSchema = generateItemPageSchema(contactGetSchema)
export type IContactPage = z.infer<typeof contactPageSchema>


export const categoryPageSchema = generateItemPageSchema(categoryGetSchema)
export type ICategoryPage = z.infer<typeof categoryPageSchema>


export const transactionPageSchema = generateItemPageSchema(transactionGetSchema)
export type ITransactionPage = z.infer<typeof transactionPageSchema>


export const serviceRequestPageSchema = generateItemPageSchema(serviceRequestGetSchema)
export type IServiceRequestPage = z.infer<typeof serviceRequestPageSchema>


export const guaranteePageSchema = generateItemPageSchema(guaranteeGetSchema)
export type IGuaranteePage = z.infer<typeof guaranteePageSchema>
