import { getConfig } from './config'

export const apiUrl = (
  url: string
) => {
  const apiUrl = getConfig().apiUrl
  return `${apiUrl}/${url}`
}

export const imageUrl = (
  url: string
) => {
  const imageUrl = getConfig().imageUrl
  return `${imageUrl}${url}`
} 
