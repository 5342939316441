import { z } from 'zod'
import { IQuery, idSchema, stringBooleanTransform, timestampSchema } from './common'
import { userGetSchema } from './user'
import { productSchema } from './product'


export const qaSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  targetId: idSchema,
  question: z.string().max(2048),
  answer: z.string().optional(),
  active: z.boolean().optional(),
})
export type IQA = z.infer<typeof qaSchema>


export const qaGetSchema =
  qaSchema
    .merge(timestampSchema)
    .extend({
      user: userGetSchema,
      target: productSchema,
    })
export type IQAGet = z.infer<typeof qaGetSchema>


export const qaPostSchema =
  qaSchema.omit({
    _id: true,
    userId: true
  })
export type IQAPost = z.infer<typeof qaPostSchema>


export const qaPatchSchema = qaPostSchema
export type IQAPatch = z.infer<typeof qaPatchSchema>


export const qaQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
  active: stringBooleanTransform.pipe(z.boolean().optional().catch(undefined))
}).partial()
export type IQAQuery = z.infer<typeof qaQuerySchema>

export const qaQueryParams: IQuery = {
  keyword: { field: ['question', 'asnwer'], operator: 'regex', control: 'text' },
  active: { field: 'active', operator: 'equal', control: 'boolean' }
}
