import { z } from 'zod'
import { imageGetSchema } from './image'
import { IQuery, idSchema, specSchema, stringBooleanTransform } from './common'
import { categoryGetSchema } from './category'


export const productSchema = z.object({
  _id: idSchema,
  code: z.coerce.number(),
  categoryId: idSchema,
  name: z.string().min(2).max(120),
  shortDesc: z.string(),
  desc: z.string(),
  price: z.coerce.number(),
  discount: z.coerce.number().min(0).max(100),
  specs: z.array(specSchema).optional(),
  active: z.boolean().optional(),
  weight: z.coerce.number().optional(),
  showcase: z.boolean().optional(),
  fullname: z.string().optional(),
  englishName: z.string().optional(),
  fullEnglishName: z.string().optional(),
  hasGuarantee: z.boolean().optional(),
  guaranteeDuration: z.coerce.number().optional(),
  freeInstall: z.boolean().optional(),
  showcaseOrder: z.coerce.number().optional(),
  inStock: z.boolean().optional(),
  isDeleted: z.boolean().optional(),
})
export type IProduct = z.infer<typeof productSchema>


export const productListItemSchema = productSchema
  .omit({ desc: true })
export type IProductListItem = z.infer<typeof productListItemSchema>


export const productGetSchema = productSchema.extend({
  category: categoryGetSchema,
  commentCount: z.number().optional(),
  qaCount: z.number().optional(),
})
export type IProductGet = z.infer<typeof productGetSchema>


export const productPostSchema = productSchema
  .omit({ _id: true })
export type IProductPost = z.infer<typeof productPostSchema>


export const productPatchSchema = productPostSchema
export type IProductPatch = z.infer<typeof productPatchSchema>


export const productImageSchema = productSchema
  .extend({ images: z.array(imageGetSchema).optional() })
export type IProductImage = z.infer<typeof productImageSchema>


export const productGetWithImagesSchema = productGetSchema
  .merge(productImageSchema)
export type IProductGetWithImages = z.infer<typeof productGetWithImagesSchema>


export const productQuerySchema = z.object({
  keyword: z.coerce.string().optional().catch(undefined),
  categoryId: idSchema.optional().catch(undefined),
  active: stringBooleanTransform.pipe(z.boolean().optional().optional().catch(undefined)),
  showcase: stringBooleanTransform.pipe(z.boolean().optional().optional().catch(undefined)),
  hasGuarantee: stringBooleanTransform.pipe(z.boolean().optional().optional().catch(undefined)),
  freeInstall: stringBooleanTransform.pipe(z.boolean().optional().optional().catch(undefined)),
  inStock: stringBooleanTransform.pipe(z.boolean().optional().optional().catch(undefined)),
}).partial()
export type IProductQuery = z.infer<typeof productQuerySchema>


export const productQueryParams: IQuery = {
  keyword: { field: ['code', 'name', 'shortDesc', 'fullname', 'englishName', 'fullEnglishName'], operator: 'regex', control: 'text' },
  categoryId: { field: 'categoryId', ref: 'category', operator: 'equal', control: 'select' },
  active: { field: 'active', operator: 'equal', control: 'boolean' },
  showcase: { field: 'showcase', operator: 'equal', control: 'boolean' },
  hasGuarantee: { field: 'showcase', operator: 'equal', control: 'boolean' },
  freeInstall: { field: 'showcase', operator: 'equal', control: 'boolean' },
  inStock: { field: 'showcase', operator: 'equal', control: 'boolean' },
}
