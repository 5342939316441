import { default as aboutcfrBeOMUEiMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/about.vue?macro=true";
import { default as indexriix2XRDAIMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/article/index.vue?macro=true";
import { default as contactthOCTQnGVBMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/contact.vue?macro=true";
import { default as faqm7iNErvmcFMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/faq.vue?macro=true";
import { default as a_45point_45to_45buy_45cheaper3iYxa0LYe1Meta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/a-point-to-buy-cheaper.vue?macro=true";
import { default as add_45product_45to_45shopping_45cartLV0z9P8t5eMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/add-product-to-shopping-cart.vue?macro=true";
import { default as after_45sales_45servicexWjRJwMyvCMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/after-sales-service.vue?macro=true";
import { default as comment_45rulesVuTgXwrMfJMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/comment-rules.vue?macro=true";
import { default as filter_45installationzeWjFtnpqaMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/filter-installation.vue?macro=true";
import { default as finish_45orderK9l1pMXuC7Meta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/finish-order.vue?macro=true";
import { default as guarantee_45conditionsFKpjiQoNjaMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/guarantee-conditions.vue?macro=true";
import { default as how_45orders_45sentsYmAcmOEkZMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/how-orders-sent.vue?macro=true";
import { default as indexdbesP48WCWMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/index.vue?macro=true";
import { default as participationSjLfd7QjutMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/participation.vue?macro=true";
import { default as payment_45methodKOGUeHItbfMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/payment-method.vue?macro=true";
import { default as privacy_45policyXRyEbmeWgIMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/privacy-policy.vue?macro=true";
import { default as search_45and_45find_45productsaA2aSIvCZlMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/search-and-find-products.vue?macro=true";
import { default as servicesnwst7WRudwMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/services.vue?macro=true";
import { default as submit_45and_45tracking_45orderXFAAnd5gwOMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide/submit-and-tracking-order.vue?macro=true";
import { default as guideHSjpMUdagZMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/guide.vue?macro=true";
import { default as indexPpZUpzDvpvMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/index.vue?macro=true";
import { default as _91articleId_93lzvewMOGKYMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/news/article/[articleId].vue?macro=true";
import { default as indexuqmZVJnDWLMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/news/index.vue?macro=true";
import { default as _91productId_93vaMKVjEoHfMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/product/[productId].vue?macro=true";
import { default as products6EPqY0HRtOMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/products.vue?macro=true";
import { default as servicesEpNCDsBu8BMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/services.vue?macro=true";
import { default as addressR7ZUGpuk1dMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/shopping-cart/address.vue?macro=true";
import { default as indexFV6wZe9eG4Meta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/shopping-cart/index.vue?macro=true";
import { default as payment_methodzNLlxAkiqhMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/shopping-cart/payment_method.vue?macro=true";
import { default as shopping_45carti60vV6VBZkMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/shopping-cart.vue?macro=true";
import { default as supportiEqzk6MkfPMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index/support.vue?macro=true";
import { default as indexqLj40bnovmMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/index.vue?macro=true";
import { default as _91_91level_93_9369of1OckWZMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/login/[[level]].vue?macro=true";
import { default as callbackAaImVaUjsUMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/payment/callback.vue?macro=true";
import { default as failedD8aIFHAAmoMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/payment/failed.vue?macro=true";
import { default as successmzIwl4lWbLMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/payment/success.vue?macro=true";
import { default as service_45requestqbf9asMl9EMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/support/service-request.vue?macro=true";
import { default as address_45listi6gJzjUIZHMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/user/address-list.vue?macro=true";
import { default as commentsloExwJbbk0Meta } from "/home/lemono/projects/lemono/apps/frontend/pages/user/comments.vue?macro=true";
import { default as favoritesc6DL9nydQJMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/user/favorites.vue?macro=true";
import { default as indexsOiLNbpaMuMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/user/index.vue?macro=true";
import { default as infoAxbZwb7Cm8Meta } from "/home/lemono/projects/lemono/apps/frontend/pages/user/info.vue?macro=true";
import { default as order_45_91id_93yUkR1vJpXmMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/user/order-[id].vue?macro=true";
import { default as ordersnPjJpGuw5kMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/user/orders.vue?macro=true";
import { default as pointsJNn9d8ok6sMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/user/points.vue?macro=true";
import { default as recently_45visitedRL2UsV2QjDMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/user/recently-visited.vue?macro=true";
import { default as userWpdj87CLxqMeta } from "/home/lemono/projects/lemono/apps/frontend/pages/user.vue?macro=true";
export default [
  {
    name: indexqLj40bnovmMeta?.name ?? undefined,
    path: indexqLj40bnovmMeta?.path ?? "/",
    meta: indexqLj40bnovmMeta || {},
    alias: indexqLj40bnovmMeta?.alias || [],
    redirect: indexqLj40bnovmMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: aboutcfrBeOMUEiMeta?.name ?? "index-about",
    path: aboutcfrBeOMUEiMeta?.path ?? "about",
    meta: aboutcfrBeOMUEiMeta || {},
    alias: aboutcfrBeOMUEiMeta?.alias || [],
    redirect: aboutcfrBeOMUEiMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/about.vue").then(m => m.default || m)
  },
  {
    name: indexriix2XRDAIMeta?.name ?? "index-article",
    path: indexriix2XRDAIMeta?.path ?? "article",
    meta: indexriix2XRDAIMeta || {},
    alias: indexriix2XRDAIMeta?.alias || [],
    redirect: indexriix2XRDAIMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/article/index.vue").then(m => m.default || m)
  },
  {
    name: contactthOCTQnGVBMeta?.name ?? "index-contact",
    path: contactthOCTQnGVBMeta?.path ?? "contact",
    meta: contactthOCTQnGVBMeta || {},
    alias: contactthOCTQnGVBMeta?.alias || [],
    redirect: contactthOCTQnGVBMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/contact.vue").then(m => m.default || m)
  },
  {
    name: faqm7iNErvmcFMeta?.name ?? "index-faq",
    path: faqm7iNErvmcFMeta?.path ?? "faq",
    meta: faqm7iNErvmcFMeta || {},
    alias: faqm7iNErvmcFMeta?.alias || [],
    redirect: faqm7iNErvmcFMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/faq.vue").then(m => m.default || m)
  },
  {
    name: guideHSjpMUdagZMeta?.name ?? undefined,
    path: guideHSjpMUdagZMeta?.path ?? "guide",
    meta: guideHSjpMUdagZMeta || {},
    alias: guideHSjpMUdagZMeta?.alias || [],
    redirect: guideHSjpMUdagZMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide.vue").then(m => m.default || m),
    children: [
  {
    name: a_45point_45to_45buy_45cheaper3iYxa0LYe1Meta?.name ?? "index-guide-a-point-to-buy-cheaper",
    path: a_45point_45to_45buy_45cheaper3iYxa0LYe1Meta?.path ?? "a-point-to-buy-cheaper",
    meta: a_45point_45to_45buy_45cheaper3iYxa0LYe1Meta || {},
    alias: a_45point_45to_45buy_45cheaper3iYxa0LYe1Meta?.alias || [],
    redirect: a_45point_45to_45buy_45cheaper3iYxa0LYe1Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/a-point-to-buy-cheaper.vue").then(m => m.default || m)
  },
  {
    name: add_45product_45to_45shopping_45cartLV0z9P8t5eMeta?.name ?? "index-guide-add-product-to-shopping-cart",
    path: add_45product_45to_45shopping_45cartLV0z9P8t5eMeta?.path ?? "add-product-to-shopping-cart",
    meta: add_45product_45to_45shopping_45cartLV0z9P8t5eMeta || {},
    alias: add_45product_45to_45shopping_45cartLV0z9P8t5eMeta?.alias || [],
    redirect: add_45product_45to_45shopping_45cartLV0z9P8t5eMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/add-product-to-shopping-cart.vue").then(m => m.default || m)
  },
  {
    name: after_45sales_45servicexWjRJwMyvCMeta?.name ?? "index-guide-after-sales-service",
    path: after_45sales_45servicexWjRJwMyvCMeta?.path ?? "after-sales-service",
    meta: after_45sales_45servicexWjRJwMyvCMeta || {},
    alias: after_45sales_45servicexWjRJwMyvCMeta?.alias || [],
    redirect: after_45sales_45servicexWjRJwMyvCMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/after-sales-service.vue").then(m => m.default || m)
  },
  {
    name: comment_45rulesVuTgXwrMfJMeta?.name ?? "index-guide-comment-rules",
    path: comment_45rulesVuTgXwrMfJMeta?.path ?? "comment-rules",
    meta: comment_45rulesVuTgXwrMfJMeta || {},
    alias: comment_45rulesVuTgXwrMfJMeta?.alias || [],
    redirect: comment_45rulesVuTgXwrMfJMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/comment-rules.vue").then(m => m.default || m)
  },
  {
    name: filter_45installationzeWjFtnpqaMeta?.name ?? "index-guide-filter-installation",
    path: filter_45installationzeWjFtnpqaMeta?.path ?? "filter-installation",
    meta: filter_45installationzeWjFtnpqaMeta || {},
    alias: filter_45installationzeWjFtnpqaMeta?.alias || [],
    redirect: filter_45installationzeWjFtnpqaMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/filter-installation.vue").then(m => m.default || m)
  },
  {
    name: finish_45orderK9l1pMXuC7Meta?.name ?? "index-guide-finish-order",
    path: finish_45orderK9l1pMXuC7Meta?.path ?? "finish-order",
    meta: finish_45orderK9l1pMXuC7Meta || {},
    alias: finish_45orderK9l1pMXuC7Meta?.alias || [],
    redirect: finish_45orderK9l1pMXuC7Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/finish-order.vue").then(m => m.default || m)
  },
  {
    name: guarantee_45conditionsFKpjiQoNjaMeta?.name ?? "index-guide-guarantee-conditions",
    path: guarantee_45conditionsFKpjiQoNjaMeta?.path ?? "guarantee-conditions",
    meta: guarantee_45conditionsFKpjiQoNjaMeta || {},
    alias: guarantee_45conditionsFKpjiQoNjaMeta?.alias || [],
    redirect: guarantee_45conditionsFKpjiQoNjaMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/guarantee-conditions.vue").then(m => m.default || m)
  },
  {
    name: how_45orders_45sentsYmAcmOEkZMeta?.name ?? "index-guide-how-orders-sent",
    path: how_45orders_45sentsYmAcmOEkZMeta?.path ?? "how-orders-sent",
    meta: how_45orders_45sentsYmAcmOEkZMeta || {},
    alias: how_45orders_45sentsYmAcmOEkZMeta?.alias || [],
    redirect: how_45orders_45sentsYmAcmOEkZMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/how-orders-sent.vue").then(m => m.default || m)
  },
  {
    name: indexdbesP48WCWMeta?.name ?? "index-guide",
    path: indexdbesP48WCWMeta?.path ?? "",
    meta: indexdbesP48WCWMeta || {},
    alias: indexdbesP48WCWMeta?.alias || [],
    redirect: indexdbesP48WCWMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/index.vue").then(m => m.default || m)
  },
  {
    name: participationSjLfd7QjutMeta?.name ?? "index-guide-participation",
    path: participationSjLfd7QjutMeta?.path ?? "participation",
    meta: participationSjLfd7QjutMeta || {},
    alias: participationSjLfd7QjutMeta?.alias || [],
    redirect: participationSjLfd7QjutMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/participation.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodKOGUeHItbfMeta?.name ?? "index-guide-payment-method",
    path: payment_45methodKOGUeHItbfMeta?.path ?? "payment-method",
    meta: payment_45methodKOGUeHItbfMeta || {},
    alias: payment_45methodKOGUeHItbfMeta?.alias || [],
    redirect: payment_45methodKOGUeHItbfMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/payment-method.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyXRyEbmeWgIMeta?.name ?? "index-guide-privacy-policy",
    path: privacy_45policyXRyEbmeWgIMeta?.path ?? "privacy-policy",
    meta: privacy_45policyXRyEbmeWgIMeta || {},
    alias: privacy_45policyXRyEbmeWgIMeta?.alias || [],
    redirect: privacy_45policyXRyEbmeWgIMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: search_45and_45find_45productsaA2aSIvCZlMeta?.name ?? "index-guide-search-and-find-products",
    path: search_45and_45find_45productsaA2aSIvCZlMeta?.path ?? "search-and-find-products",
    meta: search_45and_45find_45productsaA2aSIvCZlMeta || {},
    alias: search_45and_45find_45productsaA2aSIvCZlMeta?.alias || [],
    redirect: search_45and_45find_45productsaA2aSIvCZlMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/search-and-find-products.vue").then(m => m.default || m)
  },
  {
    name: servicesnwst7WRudwMeta?.name ?? "index-guide-services",
    path: servicesnwst7WRudwMeta?.path ?? "services",
    meta: servicesnwst7WRudwMeta || {},
    alias: servicesnwst7WRudwMeta?.alias || [],
    redirect: servicesnwst7WRudwMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/services.vue").then(m => m.default || m)
  },
  {
    name: submit_45and_45tracking_45orderXFAAnd5gwOMeta?.name ?? "index-guide-submit-and-tracking-order",
    path: submit_45and_45tracking_45orderXFAAnd5gwOMeta?.path ?? "submit-and-tracking-order",
    meta: submit_45and_45tracking_45orderXFAAnd5gwOMeta || {},
    alias: submit_45and_45tracking_45orderXFAAnd5gwOMeta?.alias || [],
    redirect: submit_45and_45tracking_45orderXFAAnd5gwOMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/guide/submit-and-tracking-order.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexPpZUpzDvpvMeta?.name ?? "index",
    path: indexPpZUpzDvpvMeta?.path ?? "",
    meta: indexPpZUpzDvpvMeta || {},
    alias: indexPpZUpzDvpvMeta?.alias || [],
    redirect: indexPpZUpzDvpvMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: _91articleId_93lzvewMOGKYMeta?.name ?? "index-news-article-articleId",
    path: _91articleId_93lzvewMOGKYMeta?.path ?? "news/article/:articleId()",
    meta: _91articleId_93lzvewMOGKYMeta || {},
    alias: _91articleId_93lzvewMOGKYMeta?.alias || [],
    redirect: _91articleId_93lzvewMOGKYMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/news/article/[articleId].vue").then(m => m.default || m)
  },
  {
    name: indexuqmZVJnDWLMeta?.name ?? "index-news",
    path: indexuqmZVJnDWLMeta?.path ?? "news",
    meta: indexuqmZVJnDWLMeta || {},
    alias: indexuqmZVJnDWLMeta?.alias || [],
    redirect: indexuqmZVJnDWLMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93vaMKVjEoHfMeta?.name ?? "index-product-productId",
    path: _91productId_93vaMKVjEoHfMeta?.path ?? "product/:productId()",
    meta: _91productId_93vaMKVjEoHfMeta || {},
    alias: _91productId_93vaMKVjEoHfMeta?.alias || [],
    redirect: _91productId_93vaMKVjEoHfMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/product/[productId].vue").then(m => m.default || m)
  },
  {
    name: products6EPqY0HRtOMeta?.name ?? "index-products",
    path: products6EPqY0HRtOMeta?.path ?? "products",
    meta: products6EPqY0HRtOMeta || {},
    alias: products6EPqY0HRtOMeta?.alias || [],
    redirect: products6EPqY0HRtOMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/products.vue").then(m => m.default || m)
  },
  {
    name: servicesEpNCDsBu8BMeta?.name ?? "index-services",
    path: servicesEpNCDsBu8BMeta?.path ?? "services",
    meta: servicesEpNCDsBu8BMeta || {},
    alias: servicesEpNCDsBu8BMeta?.alias || [],
    redirect: servicesEpNCDsBu8BMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/services.vue").then(m => m.default || m)
  },
  {
    name: shopping_45carti60vV6VBZkMeta?.name ?? undefined,
    path: shopping_45carti60vV6VBZkMeta?.path ?? "shopping-cart",
    meta: shopping_45carti60vV6VBZkMeta || {},
    alias: shopping_45carti60vV6VBZkMeta?.alias || [],
    redirect: shopping_45carti60vV6VBZkMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/shopping-cart.vue").then(m => m.default || m),
    children: [
  {
    name: addressR7ZUGpuk1dMeta?.name ?? "index-shopping-cart-address",
    path: addressR7ZUGpuk1dMeta?.path ?? "address",
    meta: addressR7ZUGpuk1dMeta || {},
    alias: addressR7ZUGpuk1dMeta?.alias || [],
    redirect: addressR7ZUGpuk1dMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/shopping-cart/address.vue").then(m => m.default || m)
  },
  {
    name: indexFV6wZe9eG4Meta?.name ?? "index-shopping-cart",
    path: indexFV6wZe9eG4Meta?.path ?? "",
    meta: indexFV6wZe9eG4Meta || {},
    alias: indexFV6wZe9eG4Meta?.alias || [],
    redirect: indexFV6wZe9eG4Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/shopping-cart/index.vue").then(m => m.default || m)
  },
  {
    name: payment_methodzNLlxAkiqhMeta?.name ?? "index-shopping-cart-payment_method",
    path: payment_methodzNLlxAkiqhMeta?.path ?? "payment_method",
    meta: payment_methodzNLlxAkiqhMeta || {},
    alias: payment_methodzNLlxAkiqhMeta?.alias || [],
    redirect: payment_methodzNLlxAkiqhMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/shopping-cart/payment_method.vue").then(m => m.default || m)
  }
]
  },
  {
    name: supportiEqzk6MkfPMeta?.name ?? "index-support",
    path: supportiEqzk6MkfPMeta?.path ?? "support",
    meta: supportiEqzk6MkfPMeta || {},
    alias: supportiEqzk6MkfPMeta?.alias || [],
    redirect: supportiEqzk6MkfPMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/index/support.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91_91level_93_9369of1OckWZMeta?.name ?? "login-level",
    path: _91_91level_93_9369of1OckWZMeta?.path ?? "/login/:level?",
    meta: _91_91level_93_9369of1OckWZMeta || {},
    alias: _91_91level_93_9369of1OckWZMeta?.alias || [],
    redirect: _91_91level_93_9369of1OckWZMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/login/[[level]].vue").then(m => m.default || m)
  },
  {
    name: callbackAaImVaUjsUMeta?.name ?? "payment-callback",
    path: callbackAaImVaUjsUMeta?.path ?? "/payment/callback",
    meta: callbackAaImVaUjsUMeta || {},
    alias: callbackAaImVaUjsUMeta?.alias || [],
    redirect: callbackAaImVaUjsUMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/payment/callback.vue").then(m => m.default || m)
  },
  {
    name: failedD8aIFHAAmoMeta?.name ?? "payment-failed",
    path: failedD8aIFHAAmoMeta?.path ?? "/payment/failed",
    meta: failedD8aIFHAAmoMeta || {},
    alias: failedD8aIFHAAmoMeta?.alias || [],
    redirect: failedD8aIFHAAmoMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/payment/failed.vue").then(m => m.default || m)
  },
  {
    name: successmzIwl4lWbLMeta?.name ?? "payment-success",
    path: successmzIwl4lWbLMeta?.path ?? "/payment/success",
    meta: successmzIwl4lWbLMeta || {},
    alias: successmzIwl4lWbLMeta?.alias || [],
    redirect: successmzIwl4lWbLMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: service_45requestqbf9asMl9EMeta?.name ?? "support-service-request",
    path: service_45requestqbf9asMl9EMeta?.path ?? "/support/service-request",
    meta: service_45requestqbf9asMl9EMeta || {},
    alias: service_45requestqbf9asMl9EMeta?.alias || [],
    redirect: service_45requestqbf9asMl9EMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/support/service-request.vue").then(m => m.default || m)
  },
  {
    name: userWpdj87CLxqMeta?.name ?? undefined,
    path: userWpdj87CLxqMeta?.path ?? "/user",
    meta: userWpdj87CLxqMeta || {},
    alias: userWpdj87CLxqMeta?.alias || [],
    redirect: userWpdj87CLxqMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: address_45listi6gJzjUIZHMeta?.name ?? "user-address-list",
    path: address_45listi6gJzjUIZHMeta?.path ?? "address-list",
    meta: address_45listi6gJzjUIZHMeta || {},
    alias: address_45listi6gJzjUIZHMeta?.alias || [],
    redirect: address_45listi6gJzjUIZHMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/user/address-list.vue").then(m => m.default || m)
  },
  {
    name: commentsloExwJbbk0Meta?.name ?? "user-comments",
    path: commentsloExwJbbk0Meta?.path ?? "comments",
    meta: commentsloExwJbbk0Meta || {},
    alias: commentsloExwJbbk0Meta?.alias || [],
    redirect: commentsloExwJbbk0Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/user/comments.vue").then(m => m.default || m)
  },
  {
    name: favoritesc6DL9nydQJMeta?.name ?? "user-favorites",
    path: favoritesc6DL9nydQJMeta?.path ?? "favorites",
    meta: favoritesc6DL9nydQJMeta || {},
    alias: favoritesc6DL9nydQJMeta?.alias || [],
    redirect: favoritesc6DL9nydQJMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/user/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexsOiLNbpaMuMeta?.name ?? "user",
    path: indexsOiLNbpaMuMeta?.path ?? "",
    meta: indexsOiLNbpaMuMeta || {},
    alias: indexsOiLNbpaMuMeta?.alias || [],
    redirect: indexsOiLNbpaMuMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: infoAxbZwb7Cm8Meta?.name ?? "user-info",
    path: infoAxbZwb7Cm8Meta?.path ?? "info",
    meta: infoAxbZwb7Cm8Meta || {},
    alias: infoAxbZwb7Cm8Meta?.alias || [],
    redirect: infoAxbZwb7Cm8Meta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/user/info.vue").then(m => m.default || m)
  },
  {
    name: order_45_91id_93yUkR1vJpXmMeta?.name ?? "user-order-id",
    path: order_45_91id_93yUkR1vJpXmMeta?.path ?? "order-:id()",
    meta: order_45_91id_93yUkR1vJpXmMeta || {},
    alias: order_45_91id_93yUkR1vJpXmMeta?.alias || [],
    redirect: order_45_91id_93yUkR1vJpXmMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/user/order-[id].vue").then(m => m.default || m)
  },
  {
    name: ordersnPjJpGuw5kMeta?.name ?? "user-orders",
    path: ordersnPjJpGuw5kMeta?.path ?? "orders",
    meta: ordersnPjJpGuw5kMeta || {},
    alias: ordersnPjJpGuw5kMeta?.alias || [],
    redirect: ordersnPjJpGuw5kMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/user/orders.vue").then(m => m.default || m)
  },
  {
    name: pointsJNn9d8ok6sMeta?.name ?? "user-points",
    path: pointsJNn9d8ok6sMeta?.path ?? "points",
    meta: pointsJNn9d8ok6sMeta || {},
    alias: pointsJNn9d8ok6sMeta?.alias || [],
    redirect: pointsJNn9d8ok6sMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/user/points.vue").then(m => m.default || m)
  },
  {
    name: recently_45visitedRL2UsV2QjDMeta?.name ?? "user-recently-visited",
    path: recently_45visitedRL2UsV2QjDMeta?.path ?? "recently-visited",
    meta: recently_45visitedRL2UsV2QjDMeta || {},
    alias: recently_45visitedRL2UsV2QjDMeta?.alias || [],
    redirect: recently_45visitedRL2UsV2QjDMeta?.redirect,
    component: () => import("/home/lemono/projects/lemono/apps/frontend/pages/user/recently-visited.vue").then(m => m.default || m)
  }
]
  }
]