import { z } from 'zod'
import { IQuery, idSchema, timestampSchema } from './common'


export const contactSchema = z.object({
  _id: idSchema,
  userId: idSchema.optional(),
  name: z.string().min(2),
  email: z.string().email(),
  subject: z.string().min(2),
  message: z.string().min(4),
})
export type IContact = z.infer<typeof contactSchema>


export const contactGetSchema = contactSchema
  .merge(timestampSchema)
export type IContactGet = z.infer<typeof contactGetSchema>


export const contactPostSchema = contactSchema
  .omit({ _id: true, userId: true })
export type IContactPost = z.infer<typeof contactPostSchema>


export const contactPatchSchema = contactPostSchema
export type IContactPatch = z.infer<typeof contactPostSchema>


export const contactQuerySchema = z.object({
  keyword: z.string(),
  userId: z.string()
}).partial()

export type IContactQuery = z.infer<typeof contactQuerySchema>

export const contactQueryParams: IQuery = {
  keyword: { field: ['name', 'email', 'subject'], operator: 'regex', control: 'text' },
  userId: { field: 'userId', ref: 'user', operator: 'equal', control: 'pick' }
}
