import { z, type ZodTypeAny } from "zod"
import { clamp } from "../shared"

export const localeSchema = z.enum(['fa', 'en', 'ar'])
export type ILocale = z.infer<typeof localeSchema>


export const idSchema = z.string().length(8)
export type IID = z.infer<typeof idSchema>


export const geoPointSchema = z.object({
  type: z.enum(['Point']),
  coordinates: z.array(z.number()).length(2)
})
export type IGeoPoint = z.infer<typeof geoPointSchema>


export const geoPolygonSchema = z.object({
  type: z.enum(['Point']),
  coordinates: z.array(z.array(z.array(z.number()).length(2)))
})
export type IGeoPolygon = z.infer<typeof geoPolygonSchema>


export const geoBoundingRectSchema = z.object({
  nelat: z.number(),
  nelng: z.number(),
  swlat: z.number(),
  swlng: z.number(),
})
export type IGeoBoundingRect = z.infer<typeof geoBoundingRectSchema>


export type IPaginate<T> = {
  data: T[],
  page: number,
  limit: number,
  total: number,
  count: number
}


export const listSchema = (schema: ZodTypeAny) => z.array(schema)


export const timestampSchema = z.object({
  createdAt: z.date(),
  updatedAt: z.date(),
})


export const sizeSchema = z.enum([
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl'
])
export type ISize = z.infer<typeof sizeSchema>


export const specSchema = z.object({
  name: z.string(),
  value: z.string(),
})
export type ISpec = z.infer<typeof specSchema>


export const stringBooleanTransform = z.coerce.string()
  .transform(val => val === 'false' ? false : true)


export const paginateQuerySchema = z.object({
  page: z.coerce.number().int().default(1).transform(value => Math.max(value, 1)),
  limit: z.coerce.number().int().default(20).transform(value => clamp(value, 10, 100)),
})
export type IPaginateQuery = z.infer<typeof paginateQuerySchema>


export type IQueryOperator = 'equal' | 'regex' | '>' | '<' | '<=' | '>='
export type IQueryControl = 'text' | 'select' | 'pick' | 'boolean'

export type IQuery = Record<string, {
  field?: string | string[],
  ref?: string,
  operator: IQueryOperator,
  control?: IQueryControl,
}>
