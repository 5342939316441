import { z } from "zod"
import { IQuery, geoPointSchema, idSchema, stringBooleanTransform } from "./common"


export const locationSchema = z.object({
  _id: idSchema,
  name: z.string().min(2),
  postTariff: z.number(),
  geolocation: geoPointSchema.optional(),
  isDeliveryAvailable: z.boolean().optional(),
  freeDelivery: z.boolean().optional(),
  deliveryTariff: z.number().optional(),
})
export type ILocation = z.infer<typeof locationSchema>


export const locationGetSchema = locationSchema
export type ILocationGet = z.infer<typeof locationGetSchema>


export const locationPostSchema = locationSchema
  .omit({ _id: true })
export type ILocationPost = z.infer<typeof locationPostSchema>


export const locationPatchSchema = locationPostSchema
export type ILocationPatch = z.infer<typeof locationPatchSchema>


export const locationQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
  freeDelivery: stringBooleanTransform.pipe(z.boolean().optional().catch(undefined))
}).partial()
export type ILocationQuery = z.infer<typeof locationQuerySchema>

export const locationQueryParams: IQuery = {
  keyword: { field: 'message', operator: 'regex', control: 'text' },
  freeDelivery: { field: 'freeDelivery', operator: 'equal', control: 'boolean' }
}
