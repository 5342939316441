import { z } from 'zod'
import { addressGetSchema, addressSchema } from './address'
import { userGetSchema } from './user'
import { shoppingCartGetSchema } from './shoppingCart'
import { IQuery, idSchema, timestampSchema } from './common'


export const orderStatusSchema = z.enum([
  'submitted',
  'payed',
  'preparing',
  'sent',
  'delivered'
])
export type IOrderStatus = z.infer<typeof orderStatusSchema>


export const orderMethodSchema = z.enum(['online', 'local'])
export type IOrderMethod = z.infer<typeof orderMethodSchema>


export const orderSchema = z.object({
  _id: idSchema,
  userId: z.string(),
  code: z.number(),
  addressId: idSchema,
  paymentMethod: orderMethodSchema,
  shoppingCartId: idSchema,
  desc: z.string().optional(),
  status: orderStatusSchema,
  discountCode: z.string().optional(),
  deliveryFee: z.number().optional(),
  total: z.number().optional(),

  bank: z.string(),
  bankOrderId: z.string(),
  bankToken: z.coerce.string(),
  bankCardMask: z.string().optional(),
  bankRRN: z.string().optional(),
})
export type IOrder = z.infer<typeof orderSchema>


export const orderGetSchema = orderSchema
  .merge(timestampSchema)
  .extend({
    user: userGetSchema,
    shoppingCart: shoppingCartGetSchema,
    address: addressGetSchema
  })
export type IOrderGet = z.infer<typeof orderGetSchema>


export const orderPostSchema = orderSchema
  .omit({ _id: true })
export type IOrderPost = z.infer<typeof orderPostSchema>


export const orderPatchSchema = orderSchema
  .omit({ _id: true })
export type IOrderPatch = z.infer<typeof orderPatchSchema>


export const orderQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
}).partial()
export type IOrderQuery = z.infer<typeof orderQuerySchema>

export const orderQueryParams: IQuery = {
  keyword: { field: ['code', 'desc'], operator: 'regex', control: 'text' },
}
