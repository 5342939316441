import { z } from 'zod'
import { IQuery, idSchema } from './common'


export const reportTypeSchema = z.enum([
  'product',
  'support',
  'purchase',
  'warranty',
  'etc'
])
export type IReportType = z.infer<typeof reportTypeSchema>


export const reportTargetTypeSchema = z.enum([
  'product',
  'order'
])
export type IReportTargetType = z.infer<typeof reportTargetTypeSchema>


export const reportSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  targetId: idSchema,
  targetType: reportTargetTypeSchema,
  type: reportTypeSchema,
  message: z.string()
})
export type IReport = z.infer<typeof reportSchema>


export const reportGetSchema = reportSchema
export type IReportGet = z.infer<typeof reportGetSchema>


export const reportPostSchema =
  reportSchema.omit({
    _id: true,
    userId: true
  })
export type IReportPost = z.infer<typeof reportPostSchema>


export const reportPatchSchema = reportPostSchema
export type IReportPatch = z.infer<typeof reportPatchSchema>


export const reportQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
  type: reportTypeSchema.optional().catch(undefined),
  targetType: reportTargetTypeSchema.optional().catch(undefined),
}).partial()
export type IReportQuery = z.infer<typeof reportQuerySchema>

export const reportQueryParams: IQuery = {
  keyword: { field: ['message'], operator: 'regex', control: 'text' },
  type: { field: 'type', operator: 'equal', control: 'select' },
  targetType: { field: 'targetType', operator: 'equal', control: 'select' },
}
