export const getConfig = () => {
  if (process.env.NODE_ENV == 'production') {
    return {
      loginCodeExpireDuration: 120,
      sessionDuration: 172800,
      valueAddedTaxRate: 0.09,
      useTestPaymentGateway: true,
      geoapifyAPIKey: "aa29a0400d6845b290b5639a6d339945",
      staticMapSize: 400,
      limit: 20,
      maxLimit: 100,
      baseUrl: 'https://lemono.co',
      apiUrl: "https://api.lemono.co/api/v1",
      imageUrl: "https://api.lemono.co/upload",
      googleAnalyticsMeasurmentID: 'G-2MH27MKHMW',
    }
  } else {
    return {
      loginCodeExpireDuration: 120,
      sessionDuration: 172800,
      valueAddedTaxRate: 0.09,
      useTestPaymentGateway: true,
      geoapifyAPIKey: "aa29a0400d6845b290b5639a6d339945",
      staticMapSize: 400,
      limit: 20,
      maxLimit: 100,
      baseUrl: 'http://localhost:3000',
      apiUrl: "http://localhost:7000/api/v1",
      imageUrl: "http://localhost:7000/upload",
      googleAnalyticsMeasurmentID: 'G-2MH27MKHMW',
    }
  }
} 
