import { z } from 'zod'
import { IQuery, idSchema, stringBooleanTransform, timestampSchema } from './common'
import { imageSchema } from './image'


export const userRoleSchema = z.enum(['admin', 'installer', 'user'])
export const userPhoneSchema = z.string().regex(/^\d+$/).length(11)

export const userSchema = z.object({
  _id: idSchema,
  phone: userPhoneSchema,
  phoneConfirmed: z.boolean(),
  firstname: z.string().min(2),
  lastname: z.string().min(2),
  role: userRoleSchema,
  active: z.boolean(),
  image: imageSchema.optional(),
  email: z.string().email().optional(),
})
export type IUser = z.infer<typeof userSchema>


export const userGetSchema = userSchema
  .merge(timestampSchema)
export type IUserGet = z.infer<typeof userGetSchema>


export const userPostSchema = userSchema
  .omit({ _id: true, image: true })
export type IUserPost = z.infer<typeof userPostSchema>


export const userPatchSchema = userPostSchema
export type IUserPatch = z.infer<typeof userPatchSchema>


export const userQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
  role: userRoleSchema.optional().catch(undefined),
  active: stringBooleanTransform.pipe(z.boolean().optional().catch(undefined))
}).partial()
export type IUserQuery = z.infer<typeof userQuerySchema>


export const userQueryParams: IQuery = {
  keyword: { field: ['firstname', 'lastname', 'phone', 'email'], operator: 'regex', control: 'text' },
  role: { field: 'role', operator: 'equal', control: 'select' },
  active: { field: 'active', operator: 'equal', control: 'boolean' }
}
