import { z } from 'zod'
import { IQuery, idSchema, specSchema } from './common'
import { imageGetSchema, imageSchema } from './image'


export const categoryTypeSchema = z.enum([
  'article',
  'product'
])
export type ICategoryType = z.infer<typeof categoryTypeSchema>


export const categorySchema = z.object({
  _id: idSchema,
  parentId: idSchema.optional(),
  name: z.string().min(2).max(64),
  longName: z.string().min(2).max(128),
  desc: z.string().min(2).max(1024),
  type: categoryTypeSchema,
  specs: z.array(specSchema).optional(),
  freeInstall: z.boolean().optional()
})
export type ICategory = z.infer<typeof categorySchema>


export const categoryGetSchema = categorySchema
  .extend({
    image: imageSchema.optional(),
    parentId: categorySchema.optional(),
    parent: categorySchema.optional()
  })
export type ICategoryGet = z.infer<typeof categoryGetSchema>


export const categoryListSchema = z.array(categorySchema)
export type ICategoryList = z.infer<typeof categoryListSchema>


export const categoryPostSchema = categorySchema
  .omit({ _id: true })
export type ICategoryPost = z.infer<typeof categoryPostSchema>


export const categoryPatchSchema = categoryPostSchema
export type ICategoryPatch = z.infer<typeof categoryPatchSchema>


export const categoryImageSchema = categorySchema
  .extend({ images: z.array(imageGetSchema).optional() })
export type ICategoryImage = z.infer<typeof categoryImageSchema>


export const categoryGetWithImagesSchema = categoryGetSchema
  .merge(categoryImageSchema)
export type ICategoryGetWithImages = z.infer<typeof categoryGetWithImagesSchema>



export const categoryQuerySchema = z.object({
  keyword: z.string(),
}).partial()
export type ICategoryQuery = z.infer<typeof categoryQuerySchema>

export const categoryQueryParams: IQuery = {
  keyword: { field: ['name', 'longName', 'desc'], operator: 'regex', control: 'text' },
}


export const categoryWithProdductCountSchema = z.array(z.object({
  id: idSchema,
  name: z.string(),
  longName: z.string(),
  desc: z.string(),
  count: z.number(),
  image: imageSchema
}))

export type ICategoryWithProductCount = z.infer<typeof categoryWithProdductCountSchema>