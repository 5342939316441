import { z } from 'zod'
import { IQuery, idSchema, stringBooleanTransform } from './common'
import { userGetSchema } from './user'


export const discountCodeType = z.enum(['points'])
export type IDiscountCodeType = z.infer<typeof discountCodeType>


export const discountCodeSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  code: z.number(),
  type: discountCodeType,
  points: z.number(),
  amount: z.number(),
  used: z.boolean(),
  expireDate: z.coerce.date(),
  minShoppingCartTotal: z.number(),
})
export type IDiscountCode = z.infer<typeof discountCodeSchema>


export const discountCodeGetSchema = discountCodeSchema
  .extend({ user: userGetSchema })
export type IDiscountCodeGet = z.infer<typeof discountCodeGetSchema>


export const discountCodePostSchema = discountCodeSchema
  .omit({ _id: true })
export type IDiscountCodePost = z.infer<typeof discountCodePostSchema>


export const discountCodePatchSchema = discountCodePostSchema
export type IDiscountCodePatch = z.infer<typeof discountCodePatchSchema>



export const discountCodeQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
  type: discountCodeType.optional().catch(undefined),
  used: stringBooleanTransform.pipe(z.boolean().optional().catch(undefined))
}).partial()
export type IDiscountCodeQuery = z.infer<typeof discountCodeQuerySchema>

export const discountCodeQueryParams: IQuery = {
  keyword: { field: 'code', operator: 'regex', control: 'text' },
  type: { field: 'type', operator: 'equal', control: 'select' },
  used: { field: 'used', operator: 'equal', control: 'boolean' }
}
