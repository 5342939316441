import { z } from 'zod'
import { geoPointSchema, idSchema } from './common'
import { locationSchema } from './location'
import { userGetSchema } from './user'


export const addressSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  locationId: idSchema,
  postalCode: z.string().length(10),
  phone: z.string().length(11),
  fullname: z.string().min(2).max(50),
  address: z.string().min(10).max(450),
  geolocation: geoPointSchema.optional()
})
export type IAddress = z.infer<typeof addressSchema>


export const addressGetSchema =
  addressSchema.extend({
    location: locationSchema,
    user: userGetSchema
  })
export type IAddressGet = z.infer<typeof addressGetSchema>


export const addressPostSchema =
  addressSchema.omit({
    _id: true,
    userId: true
  }).extend({
    locationId: idSchema
  })
export type IAddressPost = z.infer<typeof addressPostSchema>


export const addressPatchSchema = addressPostSchema
export type IAddressPatch = z.infer<typeof addressPatchSchema>

