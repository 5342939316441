import { z } from 'zod'
import { IQuery, idSchema, stringBooleanTransform } from './common'


export const ticketTypeSchema = z.enum(['technical', 'payment'])
export type ITicketType = z.infer<typeof ticketTypeSchema>


export const ticketStatusSchema = z.enum(['open', 'closed'])
export type ITicketStatus = z.infer<typeof ticketStatusSchema>


export const ticketMessageSchema = z.object({
  userId: idSchema,
  message: z.string().min(1).max(2048),
  createdAt: z.date(),
})
export type ITicketMessage = z.infer<typeof ticketMessageSchema>


export const ticketMessagePostSchema =
  ticketMessageSchema.omit({
    userId: true,
    createdAt: true
  })
export type ITicketMessagePost = z.infer<typeof ticketMessagePostSchema>


export const ticketSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  type: ticketTypeSchema,
  subject: z.string(),
  messages: z.array(ticketMessageSchema).optional(),
  status: ticketStatusSchema,
})
export type ITicket = z.infer<typeof ticketSchema>


export const ticketGetSchema = ticketSchema
export type ITicketGet = z.infer<typeof ticketGetSchema>


export const ticketPostSchema =
  ticketSchema.omit({
    _id: true,
    userId: true,
    status: true
  })
export type ITicketPost = z.infer<typeof ticketPostSchema>


export const ticketPatchSchema = ticketPostSchema
export type ITicketPatch = z.infer<typeof ticketPatchSchema>


export const ticketQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
  type: ticketTypeSchema.optional().catch(undefined),
  active: stringBooleanTransform.pipe(z.boolean().optional().catch(undefined))
}).partial()
export type ITicketQuery = z.infer<typeof ticketQuerySchema>

export const ticketQueryParams: IQuery = {
  keyword: { field: 'messages', operator: 'regex', control: 'text' },
  type: { field: 'type', operator: 'equal', control: 'select' },
  status: { field: 'status', operator: 'equal', control: 'select' },
}
