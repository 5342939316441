import type {
  IImageGet,
  IImageSize,
  IProduct,
  IProductGet,
  IProductGetWithImages
} from 'shared'
import { imageUrl } from './url'


export const getItemThumb = (
  item: { images?: IImageGet[] },
  size: IImageSize = 'sm',
  folder: string
) => {
  const placeholder = '/img/placeholder.webp'
  const images = item.images

  if (images && images.length > 0) {
    const firstImage = images[0]

    if (firstImage && firstImage.file && firstImage.file.filename)
      return imageUrl(`/${folder}/${firstImage.file.filename}_${size}.webp`)
    else
      return placeholder
  } else
    return placeholder
}

export const getProductDiscountedPrice =
  (product: IProduct | IProductGet | IProductGetWithImages) =>
    product.price - (product.price * (product.discount / 100))

    