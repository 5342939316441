import { z } from 'zod'
import { productGetWithImagesSchema } from './product'
import { idSchema } from './common'


export const favoriteTargetTypeSchema = z.enum(['product'])
export type IFavoriteTargetType = z.infer<typeof favoriteTargetTypeSchema>


export const favoriteSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  targetId: idSchema,
  targetType: favoriteTargetTypeSchema
})
export type IFavorite = z.infer<typeof favoriteSchema>


export const favoriteGetSchema =
  favoriteSchema.extend({
    target: productGetWithImagesSchema
  })
export type IFavoriteGet = z.infer<typeof favoriteGetSchema>


export const favoritePostSchema =
  favoriteSchema.omit({
    _id: true,
    userId: true
  })
export type IFavoritePost = z.infer<typeof favoritePostSchema>
