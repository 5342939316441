import { z } from "zod"
import { idSchema } from "./common"
import { productGetWithImagesSchema } from "./product"


export const recentlyVisitedSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  productId: idSchema
})
export type IRecentlyVisited = z.infer<typeof recentlyVisitedSchema>


export const recentlyVisitedGetSchema =
  recentlyVisitedSchema.extend({
    product: productGetWithImagesSchema
  })
export type IRecentlyVisitedGet = z.infer<typeof recentlyVisitedGetSchema>


export const recentlyVisitedPostSchema =
  recentlyVisitedSchema.omit({
    _id: true,
    userId: true
  })
export type IRecentlyVisitedPost = z.infer<typeof recentlyVisitedPostSchema>
