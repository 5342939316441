import { z } from 'zod'
import { IQuery, idSchema, stringBooleanTransform, timestampSchema } from './common'
import { userGetSchema } from './user'


export const rateSchema = z.number().min(0).max(5)


export const commentTargetType =
  z.enum([
    'product',
    'article'
  ])
export type ICommentTarget = z.infer<typeof commentTargetType>


export const commentSchema = z.object({
  _id: idSchema,
  userId: idSchema,
  targetId: idSchema,
  targetType: commentTargetType,
  message: z.string().max(2048),
  active: z.boolean().optional(),
})
export type IComment = z.infer<typeof commentSchema>


export const commentGetSchema =
  commentSchema
    .merge(timestampSchema)
    .extend({
      user: userGetSchema,
    })
export type ICommentGet = z.infer<typeof commentGetSchema>


export const commentPostSchema =
  commentSchema.omit({
    _id: true,
    userId: true
  })
export type ICommentPost = z.infer<typeof commentPostSchema>


export const commentPatchSchema = commentPostSchema
export type ICommentPatch = z.infer<typeof commentPatchSchema>


export const commentQuerySchema = z.object({
  keyword: z.string().optional().catch(undefined),
  targetType: commentTargetType.optional().catch(undefined),
  active: stringBooleanTransform.pipe(z.boolean().optional().catch(undefined))
}).partial()
export type ICommentQuery = z.infer<typeof commentQuerySchema>

export const commentQueryParams: IQuery = {
  keyword: { field: 'message', operator: 'regex', control: 'text' },
  targetType: { field: 'targetType', operator: 'equal', control: 'select' },
  active: { field: 'active', operator: 'equal', control: 'boolean' }
}
