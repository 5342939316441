import { defineStore } from "pinia"
import {
  type IAuthUserLogin,
  type IUserGet
} from "shared"


export const useAuthStore = defineStore('authStore', () => {
  const user = ref<IAuthUserLogin>()
  const token = ref<string>()
  const localToken = useCookie('token')

  const isLoggedIn = computed(() => user.value != undefined && token.value != undefined)
  const isAdmin = computed(() => user.value && user.value?.role == 'admin')
  const fullname = computed(() => {
    if (!user.value) return undefined
    const { firstname, lastname } = user.value
    if (firstname && lastname)
      return `${firstname} ${lastname}`
  })

  const setUser = (value: IAuthUserLogin) => user.value = value
  const setToken = (value: string) => {
    token.value = value
    localToken.value = value
  }

  const tryLoginByLocalToken = async () => {
    if (localToken.value && localToken.value.length > 0) {
      token.value = localToken.value

      const { data, error } = await useApi('auth/user')

      if (error.value == undefined)
        user.value = { ...data.value as IUserGet, token: token.value! }
    }
  }

  const submitNameEmail = async (
    firstname: string,
    lastname: string,
    email: string
  ) => {
    const { data, error } = await useApi<IAuthUserLogin>('auth/login/phone/name-email', {
      method: 'post',
      body: { firstname, lastname, email }
    })

    if (error.value == undefined) {
      user.value = data.value
      return true
    } else {
      console.log(error.value)
      return false
    }
  }

  const logout = () => {
    user.value = undefined
    token.value = undefined
    localToken.value = null
    navigateTo('')
  }

  return {
    user,
    token,
    isLoggedIn,
    isAdmin,
    fullname,
    setUser,
    setToken,
    submitNameEmail,
    logout,
    tryLoginByLocalToken,
  }
})
