import { z } from "zod"
import { idSchema } from "./common"


export const filterKeywordSchema = z.string().optional()
export type IFilterKeyword = z.infer<typeof filterKeywordSchema>


export const filterOrderSchema = z.enum([
  'newest',
  'oldest',
  'priceMin',
  'priceMax'
])
export type IFilterOrder = z.infer<typeof filterOrderSchema>


export const filterCategorySchema = z.array(idSchema)
export type IFilterCategory = z.infer<typeof filterCategorySchema>


export const filterSchema = z.object({
  keyword: filterKeywordSchema,
  order: filterOrderSchema,
  categories: filterCategorySchema
}).partial()
export type IFilter = z.infer<typeof filterSchema>
