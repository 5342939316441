import { z } from 'zod'

export const simplifiedErrorSchema = z.object({
  code: z.string(),
  expected: z.string(),
  path: z.array(z.string()),
  received: z.string(),
  message: z.string(),
})
export type ISimplifiedError = z.infer<typeof simplifiedErrorSchema>

export const simplifiedErrorListSchema = z.record(simplifiedErrorSchema)
export type ISimplifiedErrorList = z.infer<typeof simplifiedErrorListSchema>
