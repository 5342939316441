import { z } from 'zod'
import { userPhoneSchema, userSchema } from './user'


export const authUserGet = userSchema
export type IAuthUser = z.infer<typeof authUserGet>


export const authUserLogin =
  authUserGet.extend({
    token: z.string()
  })
export type IAuthUserLogin = z.infer<typeof authUserLogin>


export const registerSchema = z.object({
  phone: z.string().regex(/^\d+$/).length(11)
})
export type IRegister = z.infer<typeof registerSchema>


export const userResetPassSchema = z.object({
  phone: userPhoneSchema,
})
export type IUserResetPass = z.infer<typeof userResetPassSchema>


export const userUpdateNameSchema =
  userSchema.pick({
    firstname: true,
    lastname: true
  })
export type IUserUpdateName = z.infer<typeof userUpdateNameSchema>


export const userUpdatePhoneSchema =
  userSchema.pick({
    phone: true
  })
export type IUserUpdatePhone = z.infer<typeof userUpdatePhoneSchema>


export const userUpdateEmailSchema =
  userSchema.pick({
    email: true
  })
export type IUserUpdateEmail = z.infer<typeof userUpdateEmailSchema>
